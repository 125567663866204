import React from 'react';
import './About.css';
// https://le.utah.gov/~2022/bills/static/HB0331.html
const About = () => {
  return (
    <div className="About">
      <p className="title">About Me:</p>
      <p className="info">I'm born and raised in Utah. I graduated from Hillcrest High School in 2005, Utah State University in 2011, and currently work remotely as a software developer. I care deeply about Utah's public education and air quality. In my free time I like playing board games with friends, curling, and spending time with my family, especially in Utah's mountains and red rocks.</p>
      <p className="title">About my opponent:</p>
      <p className="info">Mr. Cullimore sponsored and passed <a
          className="App-link"
          href="https://le.utah.gov/~2022/bills/static/HB0359.html"
          target="_blank"
          rel="noopener noreferrer"
        >HB 359</a>, which directly benefitted his <a
          className="App-link"
          href="https://www.sltrib.com/news/politics/2022/04/11/draper-law-firm-keeps/"
          target="_blank"
          rel="noopener noreferrer"
        >father's lawfirm (where he works),</a> which handles many eviction cases. He was also the Senate Sponsor of the unpopular, failed <a
          className="App-link"
          href="https://le.utah.gov/~2022/bills/static/HB0331.html"
          target="_blank"
          rel="noopener noreferrer"
        >voucher bill (HB 331)</a>, that would have taken money away from our public schools when we are 50th in the nation in per pupil spending.
      </p>
      <p className="title">Other Issues:</p>
      <p className="info">I oppose spending taxpayer money on a Gondola up Little Cottonwood Canyon, which I view as a tourist attraction and not a serious transit solution.</p>
      <p className="info">I was disappointed when Utah legislators ignored the maps passed by the Independent Redistricting Commission, and instead passed a gerrymandered map drawn up in secret and revealed just minutes before being voted on. I think voters should choose their representatives, not the other way around!</p>
    </div>
  );
}

export default About;
