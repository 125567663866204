import React from 'react';
// import logo from './logo.svg';
import About from './pages/About';
import Stance from './pages/Stance';
// import ParkerHeadshot from './ParkerHeadshot.jpeg';
import CandidateHeadshot from './CandidateHeadshot.jpg';
import ParkerBondFlier from './ParkerBondFlier.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link-top"
          href="https://le.utah.gov/GIS/findDistrict2022.jsp"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check updated SD 19 maps
        </a>
        <img src={CandidateHeadshot} className="App-logo" alt="logo" />
        <p className="CTA">
          Vote Parker Bond for Utah Senate District 19
        </p>
        <a
          className="App-link"
          href="mailto:ParkerForSenate19@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          ParkerForSenate19@gmail.com
        </a>
        <a
          className="App-link"
          href="mailto:ParkerForSenate19@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request a yard sign
        </a>
        <a
          className="App-link"
          href="https://twitter.com/votebond19"
          target="_blank"
          rel="noopener noreferrer"
        >
          Follow my Twitter
        </a>
        <a
          className="App-link-final"
          href="https://donate.fundhero.io/votebond19"
          target="_blank"
          rel="noopener noreferrer"
        >
          Donate
        </a>
        <Stance />
        <About />
        <img src={ParkerBondFlier} className="Footer" alt="mailer" />
      </header>
    </div>
  );
}

export default App;
