import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="positions">
      <p className="title">What I'm for:</p>
      <p className="stance">•Support and fully fund Public Education</p>
      <p className="stance">•Improve our Air Quality</p>
      <p className="stance">•Conserve our limited water resources</p>
      <p className="stance">•Smart Growth and fiscal responsibility</p>
      <p className="stance">•Accountability from elected representatives</p>
      <p className="title">I am Endorsed by:</p>
      <p className="stance">•Canyons Education Association</p>
      <p className="stance">•Women's Democratic Club of Utah</p>
      <p className="stance">•Utah Education Association</p>
    </div>
  );
}

export default About;
